import { useState } from "react";

// Router
import { useNavigate } from "react-router-dom";

// Redux
import { useDispatch, useSelector } from "react-redux";
import { appStates, setSigned } from "redux/appSlice";

// Mui
import { Divider, IconButton, Menu, MenuItem } from "@mui/material";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

// Components
import { authSignIn, authSignOut } from "api/auth";
import { adminSite } from "utils/common";


// user menu
const userMenu = [
  {item: "我的订单", link: "/my/bookings"},
  {item: "我的支付", link: "/my/payments"},
 /* {item: "我的礼品", link: "/my/gifts"}, */
  {item: "我的账号", link: "/my/info"}
]

// admin menu
const adminMenu = [
  {item: "后台管理", url: adminSite()},
]

export default function MenuUser() 
{
  const dispatch = useDispatch()
  const { signed, isStaff } = useSelector(appStates)
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate()

  const openMenu = (e) => {
    if(!signed) authSignIn() // 没有登录，跳转到登陆站
    else setAnchorEl(e.currentTarget);
  }

  const closeMenu = () => {
    setAnchorEl(null);
  };

  // 登出网站
  const signout = async () => {
    closeMenu()
    dispatch(setSigned(false))
    authSignOut()
  }

  const nav = (menu) => {
    if(menu.link) navigate(menu.link)
    else window.location.href = menu.url
    closeMenu()
  }

  return (
    <>
    <IconButton
      color="inherit"
      onClick={openMenu}
    >
      <AccountCircleOutlinedIcon />
    </IconButton>

    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
      {userMenu.map(menu => <MenuItem key={menu.item} onClick={()=>nav(menu)}>{menu.item}</MenuItem>)}
      <Divider />
      <MenuItem onClick={()=>window._MEIQIA('showPanel')}>我的客服</MenuItem>
      {isStaff && adminMenu.map(menu => <MenuItem key={menu.item} onClick={()=>nav(menu)}>{menu.item}</MenuItem>)}
      <Divider />

      <MenuItem onClick={signout}>登出网站</MenuItem>
    </Menu>
    </>
  )
}