import { enqueueSnackbar } from "notistack";

export function noticeSuccess(message)
{
  enqueueSnackbar(message, { variant: 'success'})
}

export function noticeError(message)
{
  enqueueSnackbar(message, { variant: 'error'})
}
