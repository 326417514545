import CallbackLoader from "components/CallbackLoader";
import AuthCard from "layouts/AuthCard";

export default function Errorpage()
{
  return (
    <AuthCard>
      <CallbackLoader state={4} />
    </AuthCard>
  )
}