import { useRef, useState, useEffect } from "react"
import { Button, LinearProgress, Stack, Typography } from "@mui/material"
import { inWechat } from "utils/common"
import Dialog from "./Dialog"
import { swfitpassPaymentQRCode, paymentCheck, swfitpassPaymentOA } from "api/payment"
import { noticeError, noticeSuccess } from "utils/notice"

export default function WechatPaymentButton({paymentID}) {

  const ref = useRef()
  const [QRCode, setQRCode] = useState(false)

  const handleClick = async () => {
    if(inWechat()) {
      const res = swfitpassPaymentOA(paymentID)

      window.WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        JSON.parse(res),
        function(res){
          if(res.err_msg === "get_brand_wcpay_request:ok" ){
            noticeSuccess('支付成功')
            window.location.reload()
          }else{
            noticeError('支付失败')
          }
        }
      )
      
    } else {
      // 桌面浏览器 二维码支付
      ref.current.open()
      setQRCode(false)
      const res = await swfitpassPaymentQRCode(paymentID)
      setQRCode(res)
    }
  }

  useEffect(() => {
    const interval = setInterval(async () => {
      console.log('check')
      const res = await paymentCheck(paymentID)
      if(res === 'paid') {
        noticeSuccess('支付成功')
        window.location.reload()
      }
    }, 2000);

    if(QRCode) {
      return () => clearInterval(interval);
    } else {
      clearInterval(interval)
    }
  }, [QRCode])

  return (
    <>
    <Button variant="contained" color="error" onClick={handleClick}>微信支付</Button>

    <Dialog 
      ref={ref} 
      title="微信扫码支付"
      width="md"
      onClose={()=>setQRCode(false)}
    >
      {!QRCode && <LinearProgress />}
      <Stack alignItems='center' spacing={2}>
        {QRCode && <img src={QRCode} alt="qrcode" />}
        {QRCode && <Typography>支付完成后请等待页面自动刷新</Typography>}
      </Stack>
    </Dialog>
    </>
  )
}