/* 企业微信登录 */
import { Button } from "@mui/material"
import Dialog from "./Dialog"
import { useRef } from "react"
import { useSelector } from "react-redux"
import { appStates } from "redux/appSlice"
import { useNavigate } from "react-router-dom"

// 参考文档


export default function WeComSignInButton() 
{
  const state = 'wechat'
  const redirectUri = 'https://account.51room.com/wechat/' // 需要和授权回调域一致

  // 开放平台 网站 扫码登录
  // https://developers.weixin.qq.com/doc/oplatform/Website_App/WeChat_Login/Wechat_Login.html
  const webAppID = 'wx20c812d1f1d16b2f' 
  const webRedirectUri = redirectUri + 'qrcode';
  const qrUrl = 'https://open.weixin.qq.com/connect/qrconnect?appid='+webAppID+'&redirect_uri='+webRedirectUri+'&response_type=code&scope=snsapi_login&state='+state+'&#wechat_redirect'

  // 开放平台 公众号 微信内浏览器
  const oaAppID = 'wx49481174cd8716da'; 
  const oaRedirectUri = redirectUri + 'oa';
  const oaUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='+oaAppID+'&redirect_uri='+oaRedirectUri+'&response_type=code&scope=snsapi_userinfo&state='+state;


  const ref = useRef()
  const { isMobile } = useSelector(appStates)
  const nav = useNavigate()

  const open = () => {
    if(process.env.NODE_ENV === 'development') nav('/wechat/test?state=test&code=test')
    if(!isMobile) ref.current.open() // desktop 的情况下
    else window.location.href = oaUrl
  }

  return (
    <>
    <Button variant="contained" color="success" onClick={open}>微信登录</Button>
    <Dialog ref={ref} noHeader width="xs">
      <iframe 
        height="400"
        title="Wechat QRCode"
        frameborder="0"
        sandbox="allow-scripts allow-same-origin allow-top-navigation"
        style={{overflow: "hidden"}}
        src={qrUrl} 
      />
    </Dialog>
    </>
  )
}