/* 微信登录 callback */

import CallbackLoader from "components/CallbackLoader";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { call } from "api/api";
import AuthCard from "layouts/AuthCard";

export default function WeChat()
{
  const [searchParams] = useSearchParams();
  const { type } = useParams()

  const code = searchParams.get('code')
  const state = searchParams.get('state')

  const [loaderState, setLoaderState] = useState(1)

  const getToken = async () => {
    const res = await call('auth/wechat', {code: code, state: state, type: type})
    if(res) {
      setLoaderState(2)
    } else {
      setLoaderState(3)
    }
  }

  useEffect(() => {
    if(code !== 'undefined') getToken()
  }, [code])
  

  return (
    <AuthCard>
      <CallbackLoader state={loaderState} />
    </AuthCard>
  )
}