import { useState } from "react";
// Router
import { useNavigate } from "react-router-dom";
// Mui
import { IconButton, Menu, MenuItem } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import mainMenu from 'layouts/menu'

export default function MenuMain() {

  const navigate = useNavigate()
  const [anchorElNav, setAnchorElNav] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const nav = (menu) => {
    if(menu.link) navigate(menu.link)
    else window.location.href = menu.url
    handleCloseNavMenu()
  }

  return (
    <>
      <IconButton
        color="inherit"
        onClick={handleOpenNavMenu}
      >
        <MenuIcon />
      </IconButton>

      <Menu
        anchorEl={anchorElNav}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
      >
        { mainMenu.map( m => 
          <MenuItem
            key={m.item} 
            onClick={()=>nav(m)}
          >
            {m.item}
          </MenuItem>
          ) 
        }
      </Menu>
  </>
  );
}