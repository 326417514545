import { createBrowserRouter } from "react-router-dom";

// Layout
import Layout from 'layouts/Layout';
import ErrorPage from 'pages/ErrorPage';
import Homepage from "pages/Homepage";
import WeCom from "pages/WeCom";
import SignOut from "pages/SignOut";
import WeChat from 'pages/WeChat';

import LayoutMy from "layouts/LayoutMy";
import Bookings from "pages/Bookings";
import Payments from "pages/Payments";
import Info from "pages/Info";
import MyGifts from "pages/MyGifts";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Homepage />,
      },
      {
        path: "auth",
        element: <></>,
      },
      {
        path: "/wechat/:type",
        element: <WeChat />,
      },
      {
        path: "/wecom",
        element: <WeCom />,
      },
      {
        path: "/signout",
        element: <SignOut />,
      },
      {
        path: "my",
        element: <LayoutMy />,
        children: [
          {
            path: "bookings",
            element: <Bookings />
          },
          {
            path: "payments",
            element: <Payments />
          },
          {
            path: "gifts",
            element: <MyGifts />
          },
          {
            path: "info",
            element: <Info />
          }
        ]
      },
    ]
  }
  
]);

export default router 