/* 企业微信登录 callback */

import CallbackLoader from "components/CallbackLoader";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { call } from "api/api";
import AuthCard from "layouts/AuthCard";

export default function WeCom()
{
  const [searchParams] = useSearchParams();

  const code = searchParams.get('code')
  const appid = searchParams.get('appid')
  const state = searchParams.get('state')

  const [loaderState, setLoaderState] = useState(1)

  const getToken = async () => {
    const res = await call('auth/wecom', {code: code, appid: appid, state: state})
    if(res) {
      setLoaderState(2)
    } else {
      setLoaderState(3)
    }
  }

  useEffect(() => {
    if(code !== 'undefined') getToken()
  }, [code])
  

  return (
    <AuthCard>
      <CallbackLoader state={loaderState} />
    </AuthCard>
  )
}