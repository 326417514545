// Mui
import { AppBar, Toolbar } from "@mui/material";
import HeaderDesktop from "./HeaderDesktop";
import HeaderMobile from "./HeaderMobile";

export default function Layout({isMobile})
{

  return (
    <AppBar position="fixed">
      <Toolbar variant="dense">
        {!isMobile && <HeaderDesktop />}
        {isMobile && <HeaderMobile />}
      </Toolbar>
    </AppBar>
  )
}