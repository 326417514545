import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Stack } from "@mui/material";
import AuthCard from "layouts/AuthCard";
import WeChatSignInButton from "components/WeChatSignInButton";
import WeComSignInButton from "components/WeComSignInButton";


/* 登录页面 */
// https://account.51room.com?from=https://www.51room.com/xxxx

export default function Homepage()
{
  const [searchParams] = useSearchParams();
  const from = searchParams.get('from')

  useEffect(() => {
    // 跳转过来需要登录的会带一个参数
    if(from) localStorage.setItem('redirectBackURL', from)
  }, [])
  
  return (
    <AuthCard>
      <Stack spacing={2}>
        <WeChatSignInButton />
        <WeComSignInButton />
      </Stack>
    </AuthCard>
  )
}