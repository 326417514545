import { Box, Button, Card, CardContent, Grid, Stack } from "@mui/material";
import { redirect } from "api/api";

export default function AuthCard({children})
{
  const redirectBackURL = localStorage.getItem('redirectBackURL')

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Box alignSelf='center' mt={1} mx={1}>
                <img alt="logo" src="/logo-399x143.png" width="250" />
              </Box>
              {children}
              <Button variant="outlined" onClick={()=>redirect(redirectBackURL)} >返回</Button>
            </Stack>
          </CardContent>
        </Card>
      </Grid>         
    </Grid> 
  )
}