/* 页面 我的礼品 */
/* my/gifts */
import { useEffect, useState } from "react"
import { myGifts } from "api/my"
import { Grid, LinearProgress, Stack, Typography } from "@mui/material"


export default function MyGifts()
{
  const [gifts, setGifts] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchData = async () => {
    setLoading(true)
    const res = await myGifts()
    if(res) setGifts(res)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Stack spacing={2}>
      <Grid container>
        <Grid item lg={4} md={3}></Grid>
        <Grid item lg={4} md={6} xs={12}>
          <Stack spacing={2}>
            <Typography variant="h5">我的礼品</Typography>
            { loading && <LinearProgress /> }
            { !loading && gifts.map(gift => <Gift gift={gift} key={gift.id} />) }
            { !loading && gifts.length === 0 && <Typography>您还没有礼品。</Typography>}
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  )
}

/**/
function Gift({gift})
{

  return (<>
  {gift.type === '5' && <Flight gift={gift} />}
  
  </>)
}

/* 接机服务 */
function Flight({gift})
{

  return (<>
  {gift.id}
  </>)
}