/* 我的支付 */
import { useEffect, useState } from "react"
import { myInfo } from "api/my"
import { Card, CardContent, CardHeader, Divider, Grid, LinearProgress, Stack, Typography } from "@mui/material"

export default function Info()
{
  const [info, setInfo] = useState(null)
  const [loading, setLoading] = useState(false)

  const fetchData = async () => {
    setLoading(true)
    const res = await myInfo()
    if(res) setInfo(res)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Stack spacing={2}>
      <Grid container>
        <Grid item lg={4} md={3}></Grid>
        <Grid item lg={4} md={6} xs={12}>
          <Stack spacing={2}>
            { loading && <LinearProgress /> }
            <Card>
              <CardHeader title="我的账号" />
              <Divider />
              {info && 
              <CardContent>
                <Stack spacing={1}>

                  <Typography>ID: {info.id}</Typography>
                  <Typography>注册时间: {info.first_login_date}</Typography>
                  <Typography>Email: {info.email}</Typography>
                </Stack>
              </CardContent>
              }
            </Card>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  )
}