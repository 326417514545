import { call } from "api/api";

/* 我的订单 */
export const myBookings = async () => {
  return await call('my/bookings')
}

/* 我的支付 */
export const myPayments = async () => {
  return await call('my/payments')
}

/* 我的礼包 */
export const myGifts = async () => {
  return await call('my/gifts')
}

/* 我的账号 */
export const myInfo = async () => {
  return await call('my/info')
}