import { useEffect } from "react"
import { call } from "api/api";
import { Alert } from "@mui/material";
import AuthCard from "layouts/AuthCard";

export default function SignOut()
{

  const logout = async () => {
    await call('auth/signout')
  }

  useEffect(() => {
    logout()
  }, [])


  return (
    <AuthCard>
      <Alert severity="success">已成功登出!</Alert>
    </AuthCard>
  )
}