import { Box, Dialog as MuiDialog, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography } from "@mui/material"
import { useImperativeHandle, useState, forwardRef } from "react"
import CloseIcon from '@mui/icons-material/Close';

const Dialog = forwardRef((props, ref) => {

  const [open, setOpen] = useState(false)
  const { 
    title, 
    subtitle, 
    width, 
    action, 
    children, 
    noContentPadding, 
    noHeader, 
    onClose, 
    fullScreen, 
    fullWidth 
  } = props


  useImperativeHandle(ref, ()=> ({
    open() {
      setOpen(true)
    },
    close() {
      setOpen(false)
    }
  }))

  const closeDialog = () => {
    setOpen(false)
    if(onClose) onClose()
  }

  return (
    <MuiDialog 
      open={open} 
      onClose={closeDialog} 
      fullWidth={fullWidth} 
      maxWidth={width}
      fullScreen={fullScreen}
    >
      { !noHeader && 
      <>
      <DialogTitle sx={{background: "#1976d2", color: 'white'}} >
        <Stack direction="row" spacing={2}>
          <Stack sx={{flexGrow: 1}} spacing={0}>
            <Typography variant='h5'>{title}</Typography>
            {subtitle && <Typography variant='caption'>{subtitle}</Typography>}
          </Stack>
          <Box>
            <Stack direction="row" spacing={1}>
              {action}
              <IconButton onClick={closeDialog} size='small'><CloseIcon sx={{color:'white'}} /></IconButton>
            </Stack>
          </Box>
        </Stack>
      </DialogTitle>
      <Divider />
      </>
      }
      <DialogContent sx={noContentPadding?{background: '#eee', padding: 0}:{background: '#eee'}}>
        {children}
      </DialogContent>
    </MuiDialog>
  )

})


export default Dialog