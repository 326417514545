// Router
import { useNavigate } from "react-router-dom";

// Mui
import {Box, Button, Stack } from "@mui/material";

// Components
import MenuUser from "./MenuUser";
import mainMenu from 'layouts/menu'
import Logo from "./Logo";

export default function HeaderDesktop()
{
  const navigate = useNavigate()

  const nav = (menu) => {
    if(menu.link) navigate(menu.link)
    else window.location.href = menu.url
  }

  return (
    <Stack sx={{ flexGrow: 1 }} direction="row" spacing={2} alignItems="center">

      <Logo />

      <Box />

      <Stack sx={{ flexGrow: 1 }} direction="row" spacing={2}>
        { mainMenu.map( m => 
          <Button 
            key={m.item} 
            onClick={()=>nav(m)} 
            sx={{ my: 1, color: 'white', display: 'block' }}
          >
            {m.item}
          </Button>
          ) 
        }
      </Stack>

      <MenuUser />
    </Stack>
  )
}