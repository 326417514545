/* 微信支付接口 */
import { call } from "./api";

/* 微信支付二维码 */
export const swfitpassPaymentQRCode = async (paymentID) => {
  return await call('payment/swiftpass/qrcode', {paymentID: paymentID})
}

/* 微信支付公众号内 */
export const swfitpassPaymentOA = async (paymentID) => {
  return await call('payment/swfitpass/oa', {paymentID: paymentID})
}

/* 微信支付状态检查 */
export const paymentCheck = async (paymentID) => {
  return await call('payment/check', {paymentID: paymentID})
}