/* 我的订单 */
import { useEffect, useState } from "react"
import { myBookings } from "api/my"
import { Box, Card, CardContent, Chip, Grid, LinearProgress, Link, Stack, Typography } from "@mui/material"
import { titleCase } from "utils/common"
import { rentFormat } from "utils/common"

export default function Bookings()
{
  const [bookings, setBookings] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchData = async () => {
    setLoading(true)
    const res = await myBookings()
    if(res) setBookings(res)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Stack spacing={2}>
      <Grid container>
        <Grid item lg={4} md={3}></Grid>
        <Grid item lg={4} md={6} xs={12}>
          <Stack spacing={2}>
            <Typography variant="h5">我的订单</Typography>
            { loading && <LinearProgress /> }
            { !loading && bookings.map(booking => <Booking booking={booking} key={booking.id} />) }
            { !loading && bookings.length === 0 && <Typography>您还没有订单。</Typography>}
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  )
}


function Booking({booking})
{
  return (
    <Card>
      <CardContent>
        <Stack>
          <Link href={booking.booking_platform ? "https://"+booking.booking_platform+'/property/'+booking.accommodation_id : 'https://www.51room.com/property/view/'+booking.accommodation_id}>
            <Typography variant="h6">{titleCase(booking.room_type)}</Typography>
          </Link>
          <Typography variant="caption">{booking.accommodation}</Typography>
        </Stack>

        <Stack direction='row' spacing={2} alignItems="center">
          <Typography variant="body2">租金: {rentFormat(booking.price)}</Typography>
          <Typography variant="body2">入住: {booking.check_in}</Typography>
          <Box sx={{ flexGrow: 1 }} />
          <BookingState state={booking.state} />
        </Stack>
        <Typography variant="caption">以上信息以最终合同为准</Typography>
      </CardContent>
    </Card>
  )
}

function BookingState({state})
{
  return (<>
  {state === '0' && <Chip label="办理中" />}
  {state === '1' && <Chip label="已完成" />}
  {state === '2' && <Chip label="已取消" />}
  </>)
}