/* 我的支付 */
import { useEffect, useState } from "react"
import { myPayments } from "api/my"
import { Box, Card, CardContent, CardHeader, Divider, Grid, LinearProgress, Stack, Typography } from "@mui/material"
import dayjs from "dayjs"
import WechatPaymentButton from "components/WeChatPaymentButton"

export default function Payments()
{
  const [payments, setPayments] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchData = async () => {
    setLoading(true)
    const res = await myPayments()
    if(res) setPayments(res)
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Stack spacing={2}>
      <Grid container>
        <Grid item lg={4} md={3}></Grid>
        <Grid item lg={4} md={6} xs={12}>
          <Stack spacing={2}>
            { loading && <LinearProgress /> }
            <PaymentPending pending={payments.pending} />
            <PaymentHistory history={payments.history} />
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  )
}

// 等待客户支付的项目
function PaymentPending({pending})
{
  return (
  <>
  {pending && pending.length > 0 &&
  <Card>
    <CardHeader title="待支付" />
    <Divider />
    <CardContent>
      <Stack spacing={2}>
        {pending.map(payment => 
          <Stack direction="row" spacing={2} key={payment.id} alignItems='center'>
            <Stack>
              <Typography variant="body2">支付 英镑</Typography>
              <Typography variant="body2">{payment.note}</Typography>
            </Stack>
            <Box sx={{flexGrow: 1}} />
            <Typography>£ {payment.amount} </Typography>
            <WechatPaymentButton paymentID={payment.id} />
          </Stack>
        )}
      </Stack>
    </CardContent>
  </Card>
  }
  </>
  )
}

/* 支付记录 */
function PaymentHistory({history})
{
  return (
    <Card>
      <CardHeader title="支付记录"  />
      <Divider />
      <CardContent>
        <Stack spacing={2}>
        {history && history.map(payment => <SwiftpassPaymentItem key={payment.id} payment={payment} />)}
        </Stack>
      </CardContent>
    </Card>
  )
}

/* 英镑 支付 退款 记录 */
function SwiftpassPaymentItem({payment})
{
  const refund = (payment.message === 'refund')
  return (
    <>
    <Stack direction="row" spacing={2} key={payment.id} alignItems='center'>
      <Stack>
        <Typography variant="body2">{refund ? "退款" : "支付"} 英镑</Typography>
        {!refund && <Typography variant="body2">{payment.note}</Typography>}
        <Typography variant="caption">{dayjs(payment.ts).format('YY-MM-DD HH:mm')}</Typography>
      </Stack>
      <Box sx={{flexGrow: 1}} />
      {refund && <Typography sx={{color: 'red'}}>+ £ {Number(payment.content.refund_fee)/100} </Typography> }
      {!refund && <Typography> £ {Number(payment.content.content.local_total_fee)/100}</Typography>}
    </Stack>
    <Divider />
    </>
  )
}