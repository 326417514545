import { Alert, LinearProgress, Stack } from "@mui/material";

/**
 * 
 * @param {int} state   // 1 loading, 2 success, 3 failed
 * @returns 
 */
export default function CallbackLoader({state})
{

  return (
    <Stack spacing={2}>
      {state === 1 && <LinearProgress />}
      {state === 2 && <Alert severity="success">登录成功!</Alert>}
      {state === 3 && <Alert severity="error">登录失败!</Alert>}
      {state === 4 && <Alert severity="warning">页面错误或不存在!</Alert>}
    </Stack>
  )
}