import { Outlet } from "react-router-dom";
import { SnackbarProvider } from "notistack";

export default function Layout() {

  return (
    <SnackbarProvider 
      autoHideDuration={2000}
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Outlet />
    </SnackbarProvider>
  )
}