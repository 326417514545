import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'app',
  initialState: {
    signed: null, // 登录状态, null 还未获得检测结果
    isMobile: true, // 手机浏览
    isStaff: false,
  },
  reducers: {
    setSigned:(state, action) => {
      state.signed = action.payload
    },
    setIsMobile:(state, action) => {
      state.isMobile = action.payload
    },
    setIsStaff:(state, action) => {
      state.isStaff = action.payload
    }
  },
})

export const { 
  setSigned,
  setIsMobile,
  setIsStaff,
  setRedirectBackURL
} = slice.actions

export const appStates = state => state.app

export default slice.reducer