// Mui
import { Box, Stack } from "@mui/material";
// Components
import MenuUser from "./MenuUser";
import MenuMain from "./MenuMain";
import Logo from "./Logo";

export default function HeaderMobile() {

  return (
    <Stack sx={{ flexGrow: 1 }} direction="row" spacing={2} alignItems="center">
        <MenuMain />

        <Box sx={{ flexGrow: 1 }} />
        <Logo />
        <Box sx={{ flexGrow: 1 }} />

        <MenuUser />
    </Stack>
  );
}