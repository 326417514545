// Router
import { Outlet } from "react-router-dom";

// Mui
import { Box, LinearProgress } from '@mui/material';

// Components
import Header from 'layouts/components/Header';
import { useSelector } from "react-redux";
import { appStates } from "redux/appSlice";
import { authSite } from "utils/common";
import { authCheck } from "api/auth";
import { useEffect } from "react";
import AuthCard from "./AuthCard";

export default function LayoutMy() {

  const { isMobile, signed } = useSelector(appStates)
  const boxStyle = isMobile?{px:0, py:8}:{px:2, py:8}

  localStorage.setItem('redirectBackURL', window.location.href)

  // 初始化
  const init = async () => {
    // 是否已经登录
    const res = await authCheck()
    if(!res.signed) window.location.href = authSite()
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <>

    {signed && 
    <>
      <Header isMobile={isMobile} />
      <Box sx={boxStyle}>
        <Outlet />
      </Box>
    </>
    }

    {!signed && 
    <AuthCard>
      <LinearProgress />
    </AuthCard>
    }
      
    </>
  )
}